.daterangepickercontainer {
  position: relative;
}

.daterangepicker {
    position: absolute;
    display: flex;
    color: inherit;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin-top: 1px;
    top: 100px;
    left: 20px;
    max-width: 680px;
    z-index: 3001;
    border: 1px solid rgba(0,0,0,0.15);
    background-clip: padding-box;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.15);
}
.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.3);
  border-left: 6px solid transparent;
}

.rangecontainer {
    width: 160px;
}

.rangebuttonstyle {
  color: #08c;
  font-size: 13px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom : 8px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 4px;
}

.rangebuttontextstyle {
  padding-left: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 12px;
}

.rangeButtonSelectedStyle {
  color: #f5f5f5;
  font-size: 13px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom : 8px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 4px;
  background-color: #08c;
}

.fromDateTimeContainer {
  font-size: 13px;
  width : 270px;
  margin: 4px;
}

.fromDateHourContainer {
  border: 1px solid #f5f5f5;
  border-radius: 4px;
}

.dateTimeLabel {
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
}

.inputDate {
  height: 30px;
}

.calendarAddon {
  background-color: inherit;
}

.timeContainer {
  text-align: center;
  position: relative;
}

.timeSelectContainer {
  margin : 8px;
}

.timeIconStyle {
  position: absolute;
  top : 3px;
  left: 14px;
  font-style: normal;
  color: #555; 
}

.multipleContentOnLine {
  position: relative;
  display: inline;
  padding: 1px;
}

.buttonContainer {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  margin-right: 13px;
  margin-left: 13px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.buttonSeperator {
  padding-left: 5px;
  padding-right: 5px;
}

.applyButton {
  border-color: #4cae4c;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 4px;
  border: 1px solid #5cb85c;
}

.cancelButton {
  background-color: #fff;
  color: #333;
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.maxDateLabel {
  padding: 7px;
  font-size: 10px;
}

.monthYearContainer {
  margin : 5px;
  margin-top: 15px;
  display: flex;
}

.leftChevron {
  display: grid;
  width: 25%;
  padding: 2px;
  justify-content: left;
  font-size: 14px;
}

.rightChevron {
  display: grid;
  width: 25%;
  padding: 2px;
  justify-content: right;
  font-size: 14px;
}

.calendarGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.calendarGridFirefoxBelow35 {
  display: flex;
  text-align: center;
}

.calendarGridHeaderFirefoxBelow35 {
  width: 14%;
}

.calendarCell {
  padding: 5px;
}

.calendarCellFirefoxBelow35 {
  padding: 5px;
  width: 14%
}

.activeNotifier {
  text-align: center;
  padding-bottom: 40px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #12bc00;
  border-radius: 50%;
  display: inline-block;
}